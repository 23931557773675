.Container {
  padding: 1rem 4rem 4rem 4rem;
  padding-top: 1rem;
}
.TableConatiner thead {
  border-style: solid;
  border-width: 1;
}

.ButtonContainer {
  text-align: end;
  margin-right: -0.5rem;
  margin-bottom: 0.5rem;
}
.TableContainer table {
  width: 100%;
}
.Table td {
  padding: 0.7rem 0.75rem;
  text-align: left;
  font-size: 1.2rem;
  color: #323638;
  overflow: auto;
}
.Table tr:nth-child(even) {
  background-color: #f6f6f6;
}
.Table tr:nth-child(odd) {
  background-color: #ffffff;
}
.Table thead th {
  background-color: #fff2e5;
  color: #333333 !important;
  padding: 1rem 0.75rem;
  padding-right: 1rem;
  border-color: #e6ccb2;
  font-size: 1.3rem;
  font-weight: 700;
  resize: horizontal;
  overflow: auto;
}
.Icon {
  color: #ff8300;
  cursor: pointer;
  margin-left: 2rem;
}
.Icon :hover {
  opacity: 0.7;
}
.DisabledIcon {
  color: rgb(112, 112, 112);
  pointer-events: none;
  margin-left: 2rem;
}
.IconColumn {
  text-align: center !important;
}
.SaveIconContainer {
  display: flex;
  align-items: center;
}
