body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-size: 10px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Toastify__toast {
  position: relative;
  min-height: 43px !important;
  /* min-width: 155px !important; */
  border-radius: 7px !important;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 5px 20px !important;
  /* border-radius: 1px;sonarqube bug */
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--success {
  background: #20a323 !important;
  text-align: center !important;
}
.Toastify__toast--warning {
  background: #ef9b02 !important;
  text-align: center !important;
}
.Toastify__toast--error {
  background: #dc1500 !important;
  text-align: center !important;
}
.Toastify__toast-container {
  width: auto !important;
}
.Toastify__close-button {
  display: none;
}
.Toastify__toast-body {
  font-size: 1.6rem;
  white-space: pre-line;
}
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 0;
  background-color:#fff;
  border-radius: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background:#b5b5b5; 
  box-shadow: inset 0 0 6px #b5b5b5;
  -webkit-box-shadow: inset 0 0 6px #b5b5b5; 
}