.Container {
  margin: 1rem 0;
  margin-bottom: 2rem;
  cursor: pointer;
}
.label {
  width: 100%;
  font-size: 1.2rem;
  color: #95a1ab;
}
.DropDown {
  position: relative;
  display: block;
  font-size: 1.2rem;
  background-color: #ffffff;
  padding: 1rem;
  width: 100%;
  height: 4rem;
  box-shadow: 0rem 0px 0.5rem #00001e29;
  border-radius: 0.5rem;
  border: 1px solid #dbdbdb;
}

.dropdowncontent {
  display: block;
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  border-radius: 0.5rem;
  margin-top: 1rem;
  margin-left: -1rem;
  z-index: 5;
  max-height: 30vh;
  overflow-y: auto;
}
.dropdowncontent a {
  color: black;
  padding: 1rem;
  text-decoration: none;
  border: 0.1rem solid #dbdbdb;
  display: block;
}
.dropdowncontent a:hover {
  background-color: #f1f3f4;
}
/* .DropDown:hover .dropdowncontent {
  display: block;
} */
.DropDown:hover .dropbtn {
  background-color: transparent;
}
.required {
  color: red;
}
.icon {
  float: right;
  margin-left: 0.5rem;
}
.reverse {
  top:auto;
  bottom:100%;
}
.DisabledDropDown {
  background-color: rgba(239, 239, 239, 0.3);
  color: #C6C6C6;
}