.Navbar {
    background-color: #FF8300;
    height: 40px;
    color: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 5rem;
    font-size: 1.2rem;
  }
  
  .HeaderDisable {
    pointer-events: none;
  }
