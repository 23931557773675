/* .Container {
  padding: 4rem 10rem;
} */
.AddBtnContainer {
  text-align: right;
}
.ListItem {
  border-bottom: 1px solid #eee;
  padding: 2rem 3rem;
  background-color: #edeef0;
  margin: 1rem 0;
  border-radius: 0.5rem;
  color: #212529;
}
.ListItem:hover {
  background-color: #dbdcdf;
}
.ListItem:hover .IconContainer {
  display: block;
}
.ListItemHeader {
  font-weight: 700;
  font-size: 1.6rem;
  color: #004271;
  display: flex;
}
.IconContainer {
  text-align: right;
  width: 100%;
  padding-right: 2rem;
  /* display: none; */
}
.IconContainer span {
  margin: 0 1rem;
  cursor: pointer;
}
.ListItemCode {
  color: #8a8a8a;
  font-size: 1.2rem;
}
.ListItemDescription {
  padding: 1rem 0;
}

.ListItemSubHead {
  color: #212529;
  font-weight: 400;
}
.Dflex {
  display: flex;
  flex-wrap: inherit;
}
.ListItemSubHead div,
.ListItemSubHead > span {
  color: #004271;
  margin-left: 0.5rem;
  font-weight: 500;
}
.ListItemCharges {
  display: flex;
}
.ListItemCharges div {
  /* min-width: 100px; */
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
}
.Noproducts {
  text-align: center;
  font-weight: 500;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50vw;
  min-height: 72px;
  border: 1px solid #eee;
  margin-top: 10vh !important;
}
