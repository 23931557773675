.Container {
    display: flex;
    margin: 4rem 5rem;
    border: 1px solid #eee;
    min-height: 70vh;
}
.TabMenu {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
}
.TabMenu {
    background-color: #eee;
    /* padding-top: 1rem; */
}
.TabMenu li {
    padding: 1.5rem 2rem;
    font-weight: 500;
}
.TabMenu li:hover {
    background-color: rgb(206, 203, 203);
}
.TabItem {
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #ffff;
    color: #004271;
    border-radius: 5px;
}
.TabItem.Active {
    background-color: rgb(206, 203, 203);
    padding-left: 2.5rem
}
.TabPane {
    padding: 2rem 3rem;
}