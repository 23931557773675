.Table {
  width: 100%;
  border-collapse: inherit;
  /* border: 1px solid #E4E4E4; */
  border-top: none;
  table-layout: fixed;
}
.Table thead th {
  background-color: #fff2e5;
  color: #333333 !important;
  padding: 1rem 0.75rem;
  padding-right: 1rem;
  border-color: #e6ccb2;
  font-size: 1.3rem;
  font-weight: 700;
  resize: horizontal;
  overflow: auto;
}
.SmallTable thead th {
  padding: 0.5rem 0.75rem !important;
}
.Table thead th i {
  font-weight: bold;
  margin-right: 1rem;
  color: #333;
}
.SmallTable thead th i {
  margin-right: 0.5rem !important;
}
.Table td {
  padding: 0.7rem 0.75rem;
  text-align: left;
  font-size: 1.2rem;
  color: #323638;
  overflow: auto;
  /* border: 1px solid #E4E4E4; */
}
.Table tr:nth-child(even) {
  background-color: #f6f6f6;
}
.Table tr:nth-child(odd) {
  background-color: #ffffff;
}
.Table tr {
  border: none;
  cursor: pointer;
}
.Table tr:hover {
  background-color: #eee;
}
.SortBtn {
  display: flex;
  float: right;
  flex-direction: column;
  cursor: pointer;
}
.Nodata {
  text-align: center !important;
  height: 5rem;
}
.loaderColumn {
  position: relative;
  min-height: 100px;
}
.HighlightRow {
  background-color: #006e6e !important;
}
.HighlightRow td {
  border: none;
  color: white !important;
}
.Icon {
  cursor: pointer;
}