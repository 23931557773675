.FormContainer {
  padding: 2rem;
  padding-bottom: 0;
}
.TextAreaStyles {
  border: 1px solid #dbdbdb;
  width: 100%;
  font-size: 1.2rem;
  padding: 1rem;
}
.DescriptionLabel {
  display: block;
  font-size: 1.2rem;
  color: #bac1c6;
}
.ChargeContent {
  display: flex;
  justify-content: right;
  margin-top: 1rem;
}
.ChargeContainer {
  overflow-y: auto;
  max-height: 15rem;
  margin-top: -1.5rem;
}
.DescriptionText {
  text-align: end;
  position: relative;
  width: 10%;
  left:88%;
  top: -3rem;
  right: 1rem;
  font-size: 1.2rem;
}
.BtnContainer {
  display: flex;
  align-items: center;
  padding-left: 0 !important;
}
.PR0 {
  padding-right: 0 !important;
}
.Footer {
  text-align: end;
  padding: 2rem 0;
}