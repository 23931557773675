.Container {
  /* text-align: center; */
  padding: 2rem;
  margin-top: 4rem;
}
.Form{
  margin-left: 17rem;
}
.FormContainer {
  justify-content: center;
  align-items: center;
}
.FormContainer > div {
  padding: 3rem 3rem;
  border: 1px solid #eee;
}
.ButtonContainer {
  text-align: center;
  margin-right: 0.4rem;
}
.DomainLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 2rem;
}
.ConfirmContainer {
  padding: 0 1rem;
}
.ConfirmContainer div {
  margin: 0.5rem 0;
}
.ConfirmContent {
  font-weight: 500;
}
.InputContainer {
  margin: 1rem;
}
.BtnContainer {
  display: flex;
  align-items: center;
}
.TimerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.TimerContainer span {
  font-size: 2rem;
}
.Expired {
  font-size: 1.3rem !important;
  color: red;
}
.Spinner {
  position: relative;
  height: 3rem;
  width: 3rem;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #ff8300;
}
.IconContainer {
  text-align: end;
  margin-top: 2rem;
}
.NoData {
  font-size: 3.5rem;
  padding: 15rem 2rem 2rem 2rem;
  text-align: center;
}
.Icons {
  margin-left: 1.5rem;
}
.Error {
  border: 1px solid red;
}
.EmailFieldContainer {
  display: flex;
}
.SimQuantity {
  font-size: 1.2rem;
  color: #95a1ab;
}
/* .Mobile {
  margin-top: 0.7rem;
} */
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.338);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.Header {
  font-size: 2rem;
  letter-spacing: 0px;
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 2rem;
}
