.ModalHead {
    padding: 2rem;
    border-bottom: 1px solid #eee;
    font-weight: 600;
    font-size: 1.8rem;
    color: #002137;
  }
  .ModalContent {
    padding: 2rem 3rem;
    min-height: 100px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
  }
  .ModalFooter {
    border-top: 1px solid #eee;
    padding: 1rem 3rem;
    text-align: right;
    background-color: white;
  }
  .ModalClose {
    position: absolute;
    right: 19px;
    top: 19px;
    color: #002137;
    font-size: 16px;
    cursor: pointer;
  }
  .ModalClose img {
    width: 1.5rem;
  }
  .ModalClose img:hover {
    opacity: 0.6;
  }
  