.Button {
    display: inline-block;
    letter-spacing: 0.25px;
  
    /* padding: 1.4rem 2.5rem 1.4rem 2.5rem; */
    /* margin: 20px 0; */
    -webkit-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  
    border: 1px solid #ff8300;
    /* border: none; */
    margin: 0.5rem;
    font-weight: 600;
    font-size: 1.2rem;
    padding: 0.8rem 2rem;
    border-radius: 1px;
    background-color: #ff8300;
    color: #ffffff;
  }
  .Button:hover {
    /* background-color: #ff992c !important; */
    /* color: #fff !important; */
    opacity: 0.8;
  }
  .Button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    background-color: rgba(0,0,0,.12);
    color: #818a8f;
    pointer-events: none;
    border: 1px solid rgba(0,0,0,.12);
  }
  .Button i {
    color: #333333;
    margin-right: 0.8em;
    margin-left: 0.8rem;
  }
  .Button img {
    margin-right: 0.8rem;
    height: 2rem;
    width: 2rem;
  }
  .Button.Secondary {
    background-color: #ea2839;
    border: 1px solid #ea2839;
    color: white;
  }
  .Button.NoBorder {
    color: #ff8300;
    background-color: transparent;
    border: none;
  }
  .RightIcon {
    margin-right: 0 !important;
  }
  .LeftIcon {
    margin-left: 0 !important;
  }
  