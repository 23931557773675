.Navbar {
  /* min-height: 112px; */
  background-color: #ffffff !important;
}
.LogoContainer {
  position: relative;
}
.LogoContainer:hover {
  cursor: pointer;
}
.DeveloperText {
  position: absolute;
  top: 14px;
  width: 100%;
  font-size: 1.6rem;
  color: #212121;
  font-weight: 500;
}
.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 3.5rem 0.4rem!important;
  min-height: 81px;
  background-color: #ffffff !important;
  border-bottom: 1px solid #eee;
}
.Menu {
  margin: 0 auto;
}
.Menu ul li {
  display: inline;
  padding: 10px;
  cursor: pointer;
}
.Menu ul li a {
  color: #707070;
  text-decoration: none;
}
.Menu ul li a:hover {
  text-decoration: none;
  /* font-weight: 500; */
}
.Logo {
  height: 36px;
  width: auto;
}
.Active a {
  font-weight: 500;
  border-bottom: 2px solid #f58320;
}
.DropdownMenu {
  position: absolute;
  left: -7px;
  top: 49px;
  z-index: 101;
  /* float: left; */
  padding: 0.75rem 5px;
  /* margin: 0.125rem 0 0; */
  /* font-size: 1.5rem;  sonarqube bug*/
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  border-radius: 0.25rem;
  border-top: navajowhite;
  min-width: 130px;
  font-size: 14px;
  box-shadow: 10px 9px 14px -1px rgba(148,148,148,0.75);
  -webkit-box-shadow: 10px 9px 14px -1px rgba(148,148,148,0.75);
  -moz-box-shadow: 10px 9px 14px -1px rgba(148,148,148,0.75);
}
.DropdownItem {
  display: block;
  padding: 5px 20px;
  width: 150px !important;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0 !important;
  cursor: pointer;
  color: black;
}
.DropdownItem a {
  color: black ;
  text-decoration: none;
}
.DropdownItem:hover,.DropdownItem a:hover {
  text-decoration: none;
  font-weight: 500;
  color: #f58320;
}
.Hide {
  display: none;
}
.DropDownArrow i {
  font-size: 10px;
  font-weight: 100;
  margin-left: 7px;
}
.SignInContainer {
  position: relative;
}
.UserDetails {
  font-size: 14px;
  color: rgba(0,0,0,.87);
  margin-right: 2rem;
  cursor: pointer;
}
.UserIcon {
  font-size: 30px;
}
.UserInfo {
  margin-left: 1.5rem;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  position: relative;
  display: flex;
}
.Menu ul,
.UserDetails {
  list-style: none;
  font-size: 14px;
  color: rgba(0,0,0,.87);
  display: flex;
  flex-wrap: wrap;
}
.Menu ul li a:hover,
.UserDetails span:hover {
  color: #f58320;
}
.UserDetails span {
  padding: 16px;
  cursor: pointer;
}
.UserName {
  font-weight: 600;
}
.UserData{
  margin-top: 1rem;
}