.ModalHead {
  padding: 1.5rem 2rem;
  font-weight: 600;
  color: #212529;
  position: relative;
}
.ModalContent {
  color: #212529;
}
.ModalFooter {
  padding: 2rem 0rem;
}
.ModalClose img {
    max-width: 1.5rem;
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
}