.TextBox {
  font-size: 1.2rem;
  background-color: #ffffff;
  padding: 0.5rem 0.75rem;
  width: 100%;
  height: 4rem;
  border: 1px solid #dbdbdb;
  border-radius: 0.5rem;
  margin: 1rem 0;
}
.TextBox:disabled {
  opacity: 0.7;
  background-color: rgba(239, 239, 239, 0.3);
  color: #C6C6C6;
}
.TextBoxContainer {
  position: relative;
}
.TextBoxContainer label {
  position: absolute;
  left: 1.5rem;
  z-index: 1;
  font-size: 1.2rem;
  color: #bac1c6;
}
.TextBoxContainer label:after {
  content: " ";
  background-color: #fff;
  width: 100%;
  height: 1.4rem;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.TextBox > i {
  position: absolute;
  right: 10px;
}
.TextBox:-internal-autofill-selected,
.TextBox:-webkit-autofill,
.TextBox:-webkit-autofill:hover,
.TextBox:-webkit-autofill:focus,
.TextBox:-webkit-autofill:active {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset;
}
.required {
  color: red;
}
.Error{
  outline: none !important;
  border:1px solid red
}
